import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FbrService from '../service/FbrService';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Knob } from 'primereact/knob';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';

const chartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: 'index',
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    min: 0
                }
            }
        ]
    }
};
const basicOptions = {
    legend: {
        labels: {
            fontColor: '#495057'
        }
    },
    scales: {
        xAxes: [{
            ticks: {
                fontColor: '#495057',
                min: 0,
                max: 20
            }
        }],
        yAxes: [{
            ticks: {
                fontColor: '#495057',
            }
        }]
    }
};
const barchartOptions = {
    legend: {
        labels: {
            fontColor: '#495057'
        }
    },
    scales: {
        xAxes: [{
            ticks: {
                fontColor: '#495057',
                min: 0,
            },

        }],
        yAxes: [{
            ticks: {
                fontColor: '#495057'
            }
        }]
    }
};

const lightOptions = {
    datalabels: {
        display: true,
    },
    tooltips: {
        enabled: true,
    },
    legend: {
        labels: {
            color: '#495057'
        }
    }
};

const ordersChartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: 'index',
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    min: 0,
                    max: 20
                }
            }
        ]
    }
};

const topMargeChart = {
    labels: [],
    datasets: [
        {
            label: 'Taux de divergence par acteur',
            backgroundColor: '#0479b6',
            data: []
        }
    ]
};
const factureChart = {
    labels: [],
    datasets: [
        {
            label: 'Facture',
            backgroundColor: '#007fff',
            barThickness: 12,
            borderRadius: 12,
            data: []
        },
        {
            label: 'Payé',
            backgroundColor: '#abc9fb',
            barThickness: 12,
            borderRadius: 12,
            data: []
        },
        /* {
            label: 'Dépenses',
            backgroundColor: '#E96C86',
            data: []
        } */
    ]
}
const enqueteData = {
    labels: [],
    datasets: [
        {
            data: [],
            backgroundColor: [
                "#42846a",
                "#97bbcd",
                "#11a8ab",
                "#0e4c70",
                "#5a6267",
                "#3f95c2",
                "#d78838",
                "#4eae6a"
            ],
            hoverBackgroundColor: [
                "#42846a",
                "#97bbcd",
                "#11a8ab",
                "#0e4c70",
                "#5a6267",
                "#3f95c2",
                "#d78838",
                "#4eae6a"
            ]
        }],
    options: {
        plugins: {
            datalabels: {
                display: true,
                backgroundColor: '#ccc',
                borderRadius: 3,
                font: {
                    color: 'red',
                    weight: 'bold',
                }
            },
            doughnutlabel: {
                labels: [{
                    text: '550',
                    font: {
                        size: 20,
                        weight: 'bold'
                    }
                }, {
                    text: 'total'
                }]
            }
        }
    }
};
const margeChart = {
    labels: [],
    datasets: [
        {
            label: 'Taux de divergence ',
            data: [],
            backgroundColor: ['rgba(100, 181, 246, 0.2)'],
            borderColor: ['#64B5F6'],
            borderWidth: 3,
            fill: true
        }
    ]
};
const chart_tendance_qte = {
    labels: [],
    datasets: [
        {
            label: 'Quantités déclarées',
            backgroundColor: '#4071a7',
            borderColor: '#4071a7',
            fill: false,
            data: []
        },
        {
            label: 'Quantités validées',
            backgroundColor: '#66BB6A',
            borderColor: '#66BB6A',
            fill: false,
            data: []
        },
        {
            label: 'Quantités SNIS',
            backgroundColor: '#784179',
            borderColor: '#784179',
            fill: false,
            data: []
        },
        {
            label: 'Cible attendue',
            backgroundColor: '#F04D0C',
            borderColor: '#F04D0C',
            fill: false,
            data: []
        }
    ]
};

const barOptions = {
    indexAxis: 'y',
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef',
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef',
            }
        },
    }
};

export const Dashboard = () => {
    const appservice = new FbrService();

    const liste_region = appservice.get_liste_regions();


    const [showindicator, setShowindicator] = useState(false);
    const [rregion, setRregion] = useState('Tous');
    const [pd1, setPd1] = useState(new Date(moment(new Date()).format('YYYY') + '-01-01'));
    const [pd2, setPd2] = useState(new Date());
    const [liste_marge_mois, setListe_marge_mois] = useState(null);
    const [liste_score_prest, setListe_score_prest] = useState(null);
    const [liste_stat_rapportage, setListe_stat_rapportage] = useState(null);
    const [liste_top_marge, setListe_top_marge] = useState(null);
    const [liste_facture_pref, setListe_facture_pref] = useState(null);

    const [nb, setNb] = useState(0);
    const [nb_ec, setNb_ec] = useState(0);
    const [nb_paye, setNb_paye] = useState(0);
    const [nb_val, setNb_val] = useState(0);
    const [taux_rp, setTaux_rp] = useState('0');
    const [mt, setMt] = useState('0');
    const [tt_rgl, setTt_rgl] = useState('0');
    const [tt_rest, setTt_rest] = useState('0');
    const [taux_pay, setTaux_pay] = useState(0);

    const [marge_error_quant, setMarge_error_quant] = useState(0);
    const [marge_error_qual, setMarge_error_qual] = useState(0);
    const [nb_reco, setNb_reco] = useState(0);
    const [nb_reco_solde, setNb_reco_solde] = useState(0);

    const [nb_enq, setNb_enq] = useState(0);
    const [moy_enq, setMoy_enq] = useState(0);
    const [note_tt_enq, setNote_tt_enq] = useState(0);
    const [note_max_enq, setNote_max_enq] = useState(0);

    const [liste_stat_enquete, setListe_stat_enquete] = useState(null);
    const [liste_stat_enquete_prest, setListe_stat_enquete_prest] = useState(null);
    const [liste_stat_tendance_qte, setListe_stat_tendance_qte] = useState(null);

    const [prc_existe, setPrc_existe] = useState('0');
    const [prc_prest, setPrc_prest] = useState('0');
    const [prc_satisf, setPrc_satisf] = useState('0');
    const [score_percept, setScore_percept] = useState('0');
    const [tt_existe, setTt_existe] = useState('0');
    const [tt_prest, setTt_prest] = useState('0');
    const [tt_res, setTt_res] = useState('0');
    const [liste_stat_resultat_enquete_prest, setListe_stat_resultat_enquete_prest] = useState(null);


    const [liste_moy_enquete_famille, setListe_moy_enquete_famille] = useState(null);
    const [liste_moy_enquete_famille_prest, setListe_moy_enquete_famille_prest] = useState(null);
    const [idPrest, setIdPrest] = useState(325);

    const [idType, setIdType] = useState(0);
    const [liste_type_service, setListe_type_service] = useState(null);
    const [liste_type_service_global, setListe_type_service_global] = useState(null);



    const menuRef = useRef(null);
    const chartRef = useRef(null);
    const chartRefMarge = useRef(null);
    const chartRefFacture = useRef(null);
    const chartRefEnquete = useRef(null);
    const chartRefTendance = useRef(null);

    useEffect(() => {
        refreshDashboard(pd1, pd2, 'Tous');
    }, []);

    const refreshDashboard = (P_DATE1, P_DATE2, P_REGION) => {
        setShowindicator(true);
        appservice.get_dashboard(moment(P_DATE1).format('MM/YYYY'), moment(P_DATE2).format('MM/YYYY'), P_REGION).then((data) => {
            setShowindicator(false);
            if (data !== null && data !== undefined) {
                setListe_marge_mois(data.liste_marge_mois);
                setListe_top_marge(data.liste_top10_marge);
                setListe_facture_pref(data.liste_facture_pref);
                setListe_score_prest(data.liste_score_prest);
                setListe_stat_rapportage(data.liste_stat_rapportage);
                setListe_stat_enquete(data.liste_stat_enquete);
                setListe_moy_enquete_famille(data.liste_moy_enquete_famille);
                setNb(data.nb);
                setNb_ec(data.nb_ec);
                setNb_paye(data.nb_paye);
                setNb_val(data.nb_val);
                setNb_reco(data.nb_reco);
                setNb_reco_solde(data.nb_reco_solde);
                setListe_stat_tendance_qte(data.liste_stat_quantite_indicateur);
                setPrc_existe(data.prc_existe);
                setPrc_prest(data.prc_prest);
                setPrc_satisf(data.prc_satisf);
                setScore_percept(data.score_percept);
                setTt_existe(data.tt_existe);
                setTt_prest(data.tt_prest);
                setTt_res(data.tt_res);
                setListe_stat_resultat_enquete_prest(data.liste_stat_resultat_enquete);


                let liste_type_service_gloable = data.liste_type_service.map(x => {
                    return { value: x.idType, label: x.libType, cibleType: x.cibleType.idCodif }
                });

                setListe_type_service_global(liste_type_service_gloable);

                let liste_ts_filtre = liste_type_service_gloable.filter(x => {
                    return x.cibleType == 325
                });
                setListe_type_service(liste_ts_filtre);

                if (data.liste_stat_enquete !== null && data.liste_stat_enquete !== undefined) {
                    if (data.liste_stat_enquete.length > 0) {
                        let liste = data.liste_stat_enquete.filter(function (el) {
                            return el.typePrest === idPrest;
                        });
                        setListe_stat_enquete_prest(liste);
                    }
                }

                if (appservice.get_userconnected().profilUser == 'Agent de saisie') {
                    setMt(data.mt);
                } else {
                    setMt(appservice.formaterValueSep(parseFloat(data.mt)));
                }

                setTaux_pay(data.taux_pay);
                setTaux_rp(data.taux_rp);

                setMarge_error_quant(data.marge_error_quant);
                setMarge_error_qual(data.marge_error_qual);

                if (data.liste_marge_mois !== null && data.liste_marge_mois !== undefined) {
                    let liste_mois = data.liste_marge_mois.map(x => {
                        return x.periodeObj
                    });
                    let liste_value = data.liste_marge_mois.map(x => {
                        return x.margeError
                    });
                    margeChart.labels = liste_mois;
                    margeChart.datasets[0].data = liste_value;
                    margeChart.datasets[0].label = "Taux de divergence global";
                    margeChart.datasets[0].borderColor = "#8dc6f5";
                    margeChart.datasets[0].backgroundColor = "#e3f2fd";
                    if (chartRef.current !== null) {
                        chartRef.current.refresh();
                    }
                }
                if (data.liste_top10_marge !== null && data.liste_top10_marge !== undefined) {
                    let liste_label = data.liste_top10_marge.map(x => {
                        return x.nomPrest
                    });
                    let liste_value = data.liste_top10_marge.map(x => {
                        return x.margeError
                    });
                    topMargeChart.labels = liste_label;
                    topMargeChart.datasets[0].data = liste_value;
                    if (chartRefMarge.current !== null) {
                        chartRefMarge.current.refresh();
                    }

                }

                if (appservice.getBailleur() !== 'ENABEL' && appservice.getBailleur() !== 'KFW') {
                    if (data.liste_moy_enquete_famille !== null && data.liste_moy_enquete_famille !== undefined) {
                        let liste = data.liste_moy_enquete_famille.filter(function (el) {
                            return el.typePrest === idPrest;
                        });
                        if (liste !== null && liste !== undefined && liste !== '') {
                            let liste_label = liste.map(x => {
                                return x.lib
                            });
                            let liste_value = liste.map(x => {
                                return x.dval
                            });
                            let nb = 0;
                            let tn = 0;
                            let tt = 0;
                            for (var i = liste.length; i--;) {
                                nb = nb + liste[i].nb;
                                tn = tn + liste[i].tt_val;
                                tt = tt + liste[i].tt_max;
                            }
                            setNb_enq(nb);
                            setNote_tt_enq(tn);
                            setNote_max_enq(tt);
                            if (tt > 0) {
                                setMoy_enq(Math.round(100 * tn / tt, 2));
                            }
                            enqueteData.labels = liste_label;
                            enqueteData.datasets[0].data = liste_value;

                            if (chartRefEnquete.current !== null) {
                                chartRefEnquete.current.refresh();
                            }
                        }
                    }

                }

                if (appservice.get_userconnected().profilUser == 'Administrateur' || appservice.get_userconnected().profilUser == 'Payeur' || appservice.get_userconnected().profilUser == 'Coordinateur' || appservice.get_userconnected().profilUser == 'Consultation') {
                    if (data.liste_facture_pref !== null && data.liste_facture_pref !== undefined) {
                        let liste_label = data.liste_facture_pref.map(x => {
                            return x.prefectureSite
                        });
                        let liste_value1 = data.liste_facture_pref.map(x => {
                            return x.mttObj
                        });
                        let liste_value2 = data.liste_facture_pref.map(x => {
                            return x.mtRglObj
                        });
                        /*  let liste_value3 = data.liste_facture_pref.map(x => {
                             return x.mtDep
                         }); */
                        factureChart.labels = liste_label;
                        factureChart.datasets[0].data = liste_value1;
                        factureChart.datasets[1].data = liste_value2;
                        //factureChart.datasets[2].data = liste_value3;
                        if (chartRefFacture.current !== null) {
                            chartRefFacture.current.refresh();
                        }
                    }

                }

                //Chart stat des tendance
                if (data.liste_stat_quantite_indicateur !== null && data.liste_stat_quantite_indicateur !== undefined) {
                    let liste_tendance = data.liste_stat_quantite_indicateur.filter(function (el) {
                        return el.type_prest === 325;  //325 = centre de santé
                    });
                    let liste_label = liste_tendance.map(x => {
                        return x.moisLobj
                    });
                    let liste_ds_qd = liste_tendance.map(x => {
                        return x.valEvalLobj
                    });
                    let liste_ds_qv = liste_tendance.map(x => {
                        return x.valConfLobj
                    });
                    let liste_ds_ca = liste_tendance.map(x => {
                        return x.valPrevLobj
                    });
                    let liste_ds_snis = liste_tendance.map(x => {
                        return x.valDhis2Lobj
                    });
                    chart_tendance_qte.labels = liste_label;
                    chart_tendance_qte.datasets[0].data = liste_ds_qd;
                    chart_tendance_qte.datasets[1].data = liste_ds_qv;
                    chart_tendance_qte.datasets[2].data = liste_ds_snis;
                    chart_tendance_qte.datasets[3].data = liste_ds_ca;
                    if (chartRefTendance.current !== null) {
                        chartRefTendance.current.refresh();
                    }

                }

            }
        });
    }

    const refreshClick = (event) => {
        refreshDashboard(pd1, pd2, rregion);
    }

    const changeDataset = (event) => {
        let index = parseInt(event.currentTarget.getAttribute('data-index'));

        let label = event.currentTarget.getAttribute('data-label');
        let border = event.currentTarget.getAttribute('data-stroke');
        let backcolor = event.currentTarget.getAttribute('data-fill');

        if (liste_marge_mois !== null && liste_marge_mois !== undefined) {
            let liste_value = [];
            let liste_value_marge = [];

            let liste_mois = liste_marge_mois.map(x => {
                return x.periodeObj
            });
            if (index == 0) {
                liste_value = liste_marge_mois.map(x => {
                    return x.margeError
                });
                liste_value_marge = liste_top_marge.map(x => {
                    return x.margeError
                });
            } else if (index == 1) {
                liste_value = liste_marge_mois.map(x => {
                    return x.margeQualObj
                });
                liste_value_marge = liste_top_marge.map(x => {
                    return x.margeQualObj
                });
            } else {
                liste_value = liste_marge_mois.map(x => {
                    return x.margeQuantObj
                });
                liste_value_marge = liste_top_marge.map(x => {
                    return x.margeQuantObj
                });
            }
            margeChart.labels = liste_mois;
            margeChart.datasets[0].data = liste_value;
            margeChart.datasets[0].label = label;
            margeChart.datasets[0].borderColor = border;
            margeChart.datasets[0].backgroundColor = backcolor;
            chartRef.current.refresh();


           

        }
    };


    const refreshDataset = (event) => {
        chartRef.current.refresh(event);
    };

    const onOrderTabClick = (event) => {
        changeDataset(event);
        refreshDataset(event);
    };

    const regionChange = (event) => {
        setRregion(event.value);
        refreshDashboard(pd1, pd2, event.value);
    };

    const pd1Change = (event) => {
        setPd1(event.value);
        if (pd2 !== null) {
            refreshDashboard(moment(event.value).format('MM/YYYY'), pd2, rregion);
        }
    };

    const pd2Change = (event) => {
        setPd2(event.value);
        if (pd1 !== null) {
            refreshDashboard(pd1, moment(event.value).format('MM/YYYY'), rregion);
        }
    };

    const filterEnquete = (idP) => {
        if (liste_stat_enquete !== null && liste_stat_enquete !== undefined) {
            if (liste_stat_enquete.length > 0) {
                let liste = liste_stat_enquete.filter(function (el) {
                    return el.typePrest === idP;
                });
                setListe_stat_enquete_prest(liste);
            }
        }
    }
    const leftContents = (
        <React.Fragment>
            {appservice.get_userconnected() !== undefined && appservice.get_userconnected() !== null && appservice.get_userconnected() !== '' ?
                appservice.get_userconnected().profilUser == 'Administrateur' || appservice.get_userconnected().profilUser == 'Payeur' || appservice.get_userconnected().profilUser == 'Consultation' || appservice.get_userconnected().profilUser == 'Coordinateur' ?
                    <Dropdown options={[{ label: 'Provinces', value: 'Tous' }].concat(liste_region)} value={rregion} optionLabel="label" onChange={regionChange} className="dashbard-demo-dropdown" style={{ width: 80 }} panelStyle={{width: '200px'}}/>
                    : null
                : null}

            <Calendar value={pd1} onChange={pd1Change} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" style={{ width: 80, marginLeft: 5 }} />
            <Calendar value={pd2} onChange={pd2Change} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" style={{ width: 80, marginLeft: 5 }} />
        </React.Fragment>
    );

    const rightContents = (
        <React.Fragment>
            <Button icon="pi pi-refresh" onClick={refreshClick} loading={showindicator}/>
        </React.Fragment>
    );


    const filterEnqueteFamille = (idP) => {
        if (liste_moy_enquete_famille !== null && liste_moy_enquete_famille !== undefined) {
            if (liste_moy_enquete_famille.length > 0) {
                let liste = liste_moy_enquete_famille.filter(function (el) {
                    return el.typePrest === idP;
                });
                if (liste !== null && liste !== undefined && liste !== '') {
                    let liste_label = liste.map(x => {
                        return x.lib
                    });
                    let liste_value = liste.map(x => {
                        return x.dval
                    });
                    enqueteData.labels = liste_label;
                    enqueteData.datasets[0].data = liste_value;
                    chartRefEnquete.current.refresh();
                    let nb = 0;
                    let tn = 0;
                    let tt = 0;
                    for (var i = liste.length; i--;) {
                        nb += liste[i].nb;
                        tn += liste[i].tt_val;
                        tt += liste[i].tt_max;
                    }
                    setMoy_enq(0);
                    setNb_enq(nb);
                    setNote_tt_enq(tn);
                    setNote_max_enq(tt);
                    if (tt > 0) {
                        setMoy_enq(Math.round(100 * tn / tt, 2));
                    }
                }
            }
        }
    }

    const prestataireChange = (event) => {
        filterEnquete(event.value);
        filterEnqueteFamille(event.value);
        setIdPrest(event.value);
    };


    //Refresh chart tendance when prestataire change
    const refreshChartTendance = (event) => {
        setIdPrest(event.value);
        let type_prest = event.value;
        let liste_tendance = liste_stat_tendance_qte.filter(function (el) {
            return el.type_prest === type_prest;
        });
        let liste_label = liste_tendance.map(x => {
            return x.moisLobj
        });
        let liste_ds_qd = liste_tendance.map(x => {
            return x.valEvalLobj
        });
        let liste_ds_qv = liste_tendance.map(x => {
            return x.valConfLobj
        });
        let liste_ds_ca = liste_tendance.map(x => {
            return x.valPrevLobj
        });
        let liste_ds_snis = liste_tendance.map(x => {
            return x.valDhis2Lobj
        });
        chart_tendance_qte.labels = liste_label;
        chart_tendance_qte.datasets[0].data = liste_ds_qd;
        chart_tendance_qte.datasets[1].data = liste_ds_qv;
        chart_tendance_qte.datasets[2].data = liste_ds_snis;
        chart_tendance_qte.datasets[3].data = liste_ds_ca;
        if (chartRefTendance.current !== null) {
            chartRefTendance.current.refresh();
        }
    };


    const idTypeChange = (event) => {
        setIdType(event.value);
        let P_DATE1 = moment(pd1).format('MM/YYYY');
        let P_DATE2 = moment(pd2).format('MM/YYYY');
        setShowindicator(true);
        appservice.get_stat_tendance_indicateur(P_DATE1, P_DATE2, rregion, idPrest, event.value).then(data => {
            let liste_tendance = data;
            setShowindicator(false);
            if (liste_tendance !== null && liste_tendance !== undefined) {
                let liste_label = liste_tendance.map(x => {
                    return x.moisLobj
                });
                let liste_ds_qd = liste_tendance.map(x => {
                    return x.valEvalLobj
                });
                let liste_ds_qv = liste_tendance.map(x => {
                    return x.valConfLobj
                });
                let liste_ds_ca = liste_tendance.map(x => {
                    return x.valPrevLobj
                });
                let liste_ds_snis = liste_tendance.map(x => {
                    return x.valDhis2Lobj
                });
                chart_tendance_qte.labels = liste_label;
                chart_tendance_qte.datasets[0].data = liste_ds_qd;
                chart_tendance_qte.datasets[1].data = liste_ds_qv;
                chart_tendance_qte.datasets[2].data = liste_ds_snis;
                chart_tendance_qte.datasets[3].data = liste_ds_ca;
                if (chartRefTendance.current !== null) {
                    chartRefTendance.current.refresh();
                }
            }

        }
        );
    };



    return (
        <div className="layout-dashboard">
            {
                appservice.get_userconnected() !== undefined && appservice.get_userconnected() !== null ?
                    <>
                        <Toolbar left={leftContents} right={rightContents} style={{ backgroundColor: '#f9f9f9', marginBottom: 10 }} />
                    </>
                    : null
            }

            <div className="grid">
                {appservice.getProfil() !== 'OBC' ?
                    <>
                        <div className="col-12 md:col-6 xl:col-3">
                            <div className="card no-gutter widget-overview-box widget-overview-box-1">
                                <span className="overview-icon">
                                    <i className="pi pi-pencil"></i>
                                </span>
                                <span className="overview-title">Rapportages</span>
                                <div className="grid overview-detail">
                                    <div className="col-6">
                                        <div className="overview-number">{nb}</div>
                                        <div className="overview-subtext">Total</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="overview-number">{taux_rp} %</div>
                                        <div className="overview-subtext">Taux</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 md:col-6 xl:col-3">
                            <div className="card no-gutter widget-overview-box widget-overview-box-3">
                                <span className="overview-icon">
                                    <i className="pi pi-dollar"></i>
                                </span>
                                <span className="overview-title">Factures</span>
                                <div className="grid overview-detail">
                                    <div className="col-6">
                                        <div className="overview-number">{mt}</div>
                                        <div className="overview-subtext">Total</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="overview-number">{taux_pay} %</div>
                                        <div className="overview-subtext">Payé</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 xl:col-3">
                            <div className="card no-gutter widget-overview-box widget-overview-box-4">
                                <span className="overview-icon">
                                    <i className="pi pi-volume-up"></i>
                                </span>
                                <span className="overview-title">Taux de divergence</span>
                                <div className="grid overview-detail">
                                    <div className="col-6">
                                        <div className="overview-number">{marge_error_quant}%</div>
                                        <div className="overview-subtext">Quantité</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="overview-number">{marge_error_qual}%</div>
                                        <div className="overview-subtext">Qualité</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 xl:col-3">
                            <div className="card no-gutter widget-overview-box widget-overview-box-2">
                                <span className="overview-icon">
                                    <i className="pi pi-list"></i>
                                </span>
                                <span className="overview-title">Recommandations</span>
                                <div className="grid overview-detail">
                                    <div className="col-6">
                                        <div className="overview-number">{nb_reco}</div>
                                        <div className="overview-subtext">Total</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="overview-number">{nb_reco_solde}</div>
                                        <div className="overview-subtext">Soldé</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : null}
                <div className="col-12 xl:col-12">
                    <div className="card no-gutter orders" style={{ minHeight: 400 }}>

                        <div className="grid">

                            <div className="col-12 xl:col-6">
                                <i className="pi pi-chart-line"></i> Statistiques des taux de divergence par mois
                                <hr style={{ marginTop: 2 }} />

                                <div id="order-tabs-container" className="grid order-tabs">
                                    <div className="order-tab order-tab-new col-4 md:col-4" onClick={onOrderTabClick}
                                        data-label="Taux de divergence globale" data-index="0" data-stroke="#8dc6f5" data-fill="#e3f2fd">
                                        <i className="pi pi-plus-circle"></i>
                                        <span className="order-label">Globale</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-new.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-completed col-4 md:col-4" onClick={onOrderTabClick}
                                        data-label="Taux de divergence qualité" data-index="1" data-stroke="#c5cae9" data-fill="#e8eaf6">
                                        <i className="pi pi-check-circle"></i>
                                        <span className="order-label">Qualité</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-completed.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-refunded col-4 md:col-4" onClick={onOrderTabClick}
                                        data-label="Taux de divergence quantité" data-index="2" data-stroke="#b2dfdb" data-fill="#e0f2f1">
                                        <i className="pi pi-refresh"></i>
                                        <span className="order-label">Quantité</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-refunded.svg" alt="order" />
                                    </div>
                                </div>
                                <div className="overview-chart">
                                    <Chart ref={chartRef} type="line" height="170w" data={margeChart} options={ordersChartOptions} id="order-chart"></Chart>
                                </div>
                            </div>
                            <div className="col-12 xl:col-6">
                                <div className="overview-chart">
                                    <i className="pi pi-chart-bar"></i> Statistiques des factures par zone
                                    <hr style={{ marginTop: 2 }} />
                                    <Chart type="bar" ref={chartRefFacture} data={factureChart} options={barchartOptions} height="205w" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="col-12 xl:col-6">
                    <div className="card" style={{ minHeight: 550 }}>
                        <div className="card-header">
                            <h5>Statistiques des enquêtes par item</h5>
                            <Dropdown options={[{ label: 'Centre de Santé', value: 325 }, { label: 'Hopital', value: 323 }, { label: 'DPS', value: 326 }, { label: 'DRS', value: 324 }]} value={idPrest} optionLabel="label" onChange={prestataireChange} className="dashbard-demo-dropdown" />
                        </div>
                        <DataTable value={liste_stat_enquete_prest} paginator={true} rows={100} alwaysShowPaginator={false} responsiveLayout='scroll' responsive={true}
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            rowGroupMode="rowspan"
                            sortField="libLenq" sortMode="single"
                            sortOrder={1}
                            groupRowsBy="libLenq"
                        >
                            <Column field="libLenq" header="Indicateur" />
                            <Column field="appEnq" header="Cotation" style={{ width: '17%' }} />
                            <Column field="nbEnq" header="Nb" style={{ width: '11%' }} />
                            <Column field="taux" body={(rowData, column) => {
                                return <div>
                                    <span style={{ fontWeight: 'bold' }}>{rowData.taux} %</span>
                                </div>
                            }} header="Taux" style={{ width: '16%' }} />
                        </DataTable>
                    </div>
                </div>

                <div className="col-12 xl:col-6">
                    <div className="card" style={{ minHeight: 550 }}>
                        <div className="card-header">
                            <h5>Statistiques des enquêtes par famille</h5>
                            <Dropdown options={[{ label: 'Centre de Santé', value: 325 }, { label: 'Hopital', value: 323 }]} value={idPrest} optionLabel="label" onChange={prestataireChange} className="dashbard-demo-dropdown" />
                        </div>
                        <Chart type="doughnut" ref={chartRefEnquete} data={enqueteData} options={lightOptions} style={{ width: '100%' }} className="w-full md:w-25rem" />
                        <hr />
                        <center>
                            <div className="grid">
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{nb_enq} </label><br/>
                                    <label  style={{ fontSize: 12 }}>Total enquêté</label>
                                </div>
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{note_tt_enq} </label><br/>
                                    <label  style={{ fontSize: 12 }}>Notes obtenues</label>
                                </div>
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{note_max_enq} </label><br/>
                                    <label  style={{ fontSize: 12 }}>Total possible</label>
                                </div>
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{moy_enq} % </label><br/>
                                    <label  style={{ fontSize: 12 }}>Moyenne </label>
                                </div>
                                
                            </div>
                        </center>
                    </div>
                </div>



                <div className="col-12 xl:col-6">
                    <div className="card" style={{ minHeight: 520 }}>
                        <i className="pi pi-chart-line"></i> Statistiques des résultats des enquêtes
                        <hr style={{ marginTop: 2 }} />
                        <div className="col-12">
                            <Chart type="pie" data={{
                                labels: ['Trouvé', 'Non trouvé'],
                                datasets: [
                                    {
                                        data: [tt_existe, tt_res - tt_existe],
                                        backgroundColor: [
                                            "#66BB6A",
                                            "#E96C86"
                                        ],
                                        hoverBackgroundColor: [
                                            "#66BB6A",
                                            "#E96C86"
                                        ]
                                    }
                                ]
                            }} style={{ width: '100%', height: '100%' }} className="w-full md:w-24rem" />
                        </div>
                        <hr />
                        <center>
                            <div className="grid">
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{tt_res} </label><br/>
                                    <label style={{ fontSize: 12 }}>Total</label>
                                </div>
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{tt_existe} </label><br/>
                                    <label style={{ fontSize: 12 }}>Existance</label>
                                </div>
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{tt_prest} </label><br/>
                                    <label style={{ fontSize: 12 }}>Prestation</label>
                                </div>
                                <div className="col-6 md:col-3">
                                    <label style={{ fontWeight: 'bold', fontSize: 22 }} >{score_percept} % </label><br/>
                                    <label style={{ fontSize: 12 }}>Score </label>
                                </div>
                                
                            </div>
                        </center>
                    </div>
                </div>

                <div className="col-12 xl:col-6">
                    <div className="card" style={{ minHeight: 520 }}>

                        <i className="pi pi-chart-line"></i> Statistiques des résultats des enquêtes par acteur
                        <hr style={{ marginTop: 2 }} />
                        <DataTable value={liste_stat_resultat_enquete_prest} paginator rows={12} alwaysShowPaginator={false} responsive={true}
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        >
                            <Column field="nomPrest" header="Acteur" filter={true} filterMatchMode='contains' />
                            <Column field="prc_existe" body={(rowData, column) => {
                                return <div>
                                    <span style={{}}>{rowData.prc_existe}</span>
                                </div>
                            }} header="% Existe" style={{ width: '14%' }} />
                            <Column field="prc_prest" body={(rowData, column) => {
                                return <div>
                                    <span style={{}}>{rowData.prc_prest}</span>
                                </div>
                            }} header="% Prest." style={{ width: '14%' }} />
                            <Column field="prc_satisf" body={(rowData, column) => {
                                return <div>
                                    <span style={{}}>{rowData.prc_satisf}</span>
                                </div>
                            }} header="% Satisf." style={{ width: '14%' }} />
                            <Column field="score_percept" body={(rowData, column) => {
                                return <div>
                                    <span style={{}}>{rowData.prc_existe}</span>
                                </div>
                            }} header="Score percept." style={{ width: '15%' }} />
                        </DataTable>
                    </div>
                </div>


                {
                    appservice.get_userconnected() !== undefined && appservice.get_userconnected() !== null ?
                        <>
                            {appservice.get_userconnected().profilUser == 'Administrateur' || appservice.get_userconnected().profilUser == 'Payeur' || appservice.get_userconnected().profilUser == 'Coordinateur' || appservice.get_userconnected().profilUser == 'Consultation' ?
                                <>
                                    <div className="col-12 xl:col-12">
                                        <div className="card" style={{ minHeight: 400 }}>
                                            <div className="card-header">
                                                <i className="pi pi-chart-line"></i> Tendances des indicateurs
                                                <Dropdown options={[{ label: 'Centre de Santé', value: 325 }, { label: 'Hopital', value: 323 }, { label: 'ZONE', value: 326 }, { label: 'DPS', value: 324 }]} value={idPrest} optionLabel="label" onChange={refreshChartTendance} className="dashbard-demo-dropdown" />
                                                <Dropdown options={liste_type_service} value={idType} optionLabel="label" style={{ width: 250 }} onChange={idTypeChange} className="dashbard-demo-dropdown" filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{width: '500px'}}/>
                                                {showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginLeft: -10 }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" className="dashbard-demo-dropdown" /> : null}
                                            </div>
                                            <hr style={{}} />
                                            <Chart options={chartOptions} responsive={true} type="line" data={chart_tendance_qte} height="100w" ref={chartRefTendance} />
                                        </div>
                                    </div>

                                    <div className="col-12 xl:col-12">
                                        <div className="card" style={{ minHeight: 370 }}>
                                            <i className="pi pi-chart-line"></i> Statistiques des rapportages
                                            <hr style={{ marginTop: 2 }} />
                                            <DataTable value={liste_stat_rapportage} responsive={true} paginator rows={20} alwaysShowPaginator={false} csvSeparator=";"
                                                className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                                <Column field="prefectureSite" header="Zone" sortable={true} />
                                                <Column field="regionSite" header="DPS" sortable={true} style={{ width: '12%' }} />
                                                <Column field="nb_prev" header="Nb prévu" style={{ width: '10%' }} sortable={true} />
                                                <Column field="nb" header="Nb rapp." style={{ width: '11%' }} sortable={true} />
                                                <Column field="taux_rp" body={(rowData, column) => {
                                                    return <div>
                                                        <span>{rowData.taux_rp} %</span>
                                                    </div>
                                                }} header="Taux rapp." style={{ width: '12%' }} sortable={true} />
                                                <Column field="nb_val" header="Nb validé" style={{ width: '11%' }} sortable={true} />
                                                <Column field="nb_ec" header="Nb attente" style={{ width: '11%' }} sortable={true} />
                                                <Column field="nb_pay" header="Nb payé" style={{ width: '10%' }} sortable={true} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </>
                                : null}
                        </>
                        : null
                }

            </div>
        </div>
    );
}

import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

export class FormBudget extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(moment('01/01/' + moment(new Date()).format('YYYY'), 'YYYY-MM-DD').format('YYYY-MM-DD')),
            pd2: new Date(),

            moisBudget: new Date(),

            liste_budget: [],
            listeprest: [],
            selectedData: null,
            idBudget: null,

            idPrest: null,
            idType: null,

            liste_ligne: [],

            ttCA: 0,
            ttMt: 0,

            ridPrest: null,
            ridType: null,
            msg: '',
            modif: false,
            visible: false,
            visible_form_new_budget: false,
            visible_dlg_modif: false,
            visible_del_ligne: false,
            showindicator: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.quantEditor = this.quantEditor.bind(this);


        this.FbrService = new FbrService();
    }

    conf_delete_new_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.idBudget == this.state.liste_ligne[i].idBudget) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }


    componentDidMount() {
        this.get_liste_data();
        this.get_liste_prestataire();
    }

    quantEditor(options) {
        return <InputNumber value={options.value} min={0} onChange={(e) => options.editorCallback(e.value)} />
    }

    onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        let val = newValue + '';
        if (val.length > 0) {
            rowData[field] = newValue;
        } else {
            event.preventDefault();
        }
        this.calculer_total();
    }

    calculer_total() {
        let _ttCA = 0;
        let _ttMt = 0;

        let liste = this.state.liste_ligne;

        if (liste != null) {
            for (var i = liste.length; i--;) {
                _ttCA += liste[i].valPrevBudget;
                _ttMt += liste[i].valPrevBudget * liste[i].idPeval.puPeval;
            }
        }
        this.setState({ ttCA: _ttCA, ttMt: _ttMt });
    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif }
                    });
                    this.setState({ listeprest: liste });
                }
            }
        });
    }

    actionTemplate_new_ligne(rowData) {
        return <Button onClick={() => this.conf_delete_new_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.debut).format('MM/YYYY');
        let spd2 = moment(this.state.fin).format('MM/YYYY');
        this.FbrService.get_liste_budget(spd1, spd2, this.state.ridType, this.state.ridPrest).then(data => this.setState({ liste_budget: data, showindicator: false }));
    }

    get_liste_ligne_budget_saisie() {
        this.FbrService.get_liste_budget_for_saisie(this.state.idPrest, moment(this.state.moisBudget).format('MM/YYYY')).then(data => {
            this.setState({ liste_ligne: data, showindicator: false });
            console.log(data);
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_budget();
            this.get_liste_data();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ valPrevBudget: data.valPrevBudget });
            this.setState({ selectedData: data });
            this.setState({ modif: true });
            this.setState({ visible_dlg_modif: true });
        }
    }


    annule_budget() {
        this.setState({ idBudget: 0 });
        this.setState({ modif: false });
        this.setState({ idPrest: null });
        this.setState({ moisBudget: null });
        this.setState({ liste_ligne: [] });
        this.forceUpdate();
    }


    enreg_budget() {
        if (this.state.idPrest == null || this.state.moisBudget == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                for (var i = this.state.liste_ligne.length; i--;) {
                    this.state.liste_ligne[i].iduser = this.FbrService.get_userconnected().idUser;
                }
                this.FbrService.enreg_budget_batch(this.state.liste_ligne).then(data => this.resultat(data.code, data.contenu));
            } else {
                let selectedData = this.state.selectedData;
                selectedData.valPrevBudget = this.state.valPrevBudget;
                this.FbrService.enreg_budget(selectedData).then(data => this.resultat(data.code, data.contenu));

            }
        }
    }

    delete_budget() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_budget(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }



    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: '' });
            this.setState({ visible: true });
        }
    }



    itemTemplateRech(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="grid" >
                    <div className="col-12 md:col-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="col-12 md:col-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="grid" style={{ padding: 10 }}>
                    <div className="col-12 md:col-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="col-12 md:col-4">
                        <label>{option.typePrest}</label>
                    </div>
                </div>
            );
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_data(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    toogleDlg() {
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" style={{ width: 120 }} onClick={this.toogleDlg} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    render() {


        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_budget();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={3} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.ttCA} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.FbrService.formaterValueSep(this.state.ttMt)} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid card" style={{ minHeight: 100 }}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Modification" visible={this.state.visible_dlg_modif} style={{ width: '300px' }} modal={true} onHide={(e) => this.setState({ visible_dlg_modif: false })}
                    footer={
                        <>
                            <Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                this.setState({ visible_dlg_modif: false });
                            }} className="p-button-secondary" />
                            <Button label="Terminer" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_budget()} />
                        </>
                    }>
                    <div className="card p-fluid formgrid grid">
                        <div className="field col-3">
                            <label >CA * </label>
                        </div>
                        <div className="field col-9">
                            <InputNumber value={this.state.valPrevBudget} onChange={(e) => this.setState({ valPrevBudget: e.value })} />
                        </div>
                    </div>
                </Dialog>

                {
                    this.state.visible_dlg_form == true ?
                        <div style={{ padding: 5 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                                    <i className="pi pi-pencil"></i>  NOUVEAUX BUDGETISATION
                                </label>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                <label>
                                    <Button label="Liste budget" className="p-button-help" icon="pi pi-list" onClick={() => {
                                        this.setState({ visible_dlg_form: false, liste_ligne: [] });
                                    }} />
                                </label>
                            </div>
                            <hr />
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-2">
                                    <label htmlFor="firstname2">Acteur *</label>
                                </div>
                                <div className="field col-12 md:col-4">
                                    <Dropdown id="idPrest" value={this.state.idPrest} options={this.state.listeprest} optionLabel='label' filter={true} filterBy='label,typePrest' filterMatchMode='contains' onChange={(e) => this.setState({ idPrest: e.target.value }, () => {
                                        this.setState({ liste_ligne: [] });
                                        this.get_liste_ligne_budget_saisie();
                                    })} autoWidth={false}
                                        panelStyle={{ width: '500px' }} appendTo={document.body}
                                    />
                                </div>

                                <div className="field col-12 md:col-2">
                                    <label htmlFor="firstname2">Mois *</label>
                                </div>
                                <div className="field col-12 md:col-4">
                                    <Calendar id="monthpicker" value={this.state.moisBudget} onChange={(e) => this.setState({ moisBudget: e.target.value }, () => {
                                        this.setState({ liste_ligne: [] });
                                        let val = moment(this.state.moisBudget).format('MM/YYYY');
                                        this.setState({ rMois: val.split("/")[0] + '/' + val.split("/")[1] }, () => {
                                            this.get_liste_ligne_budget_saisie(this.state.idPrest, this.state.rMois);
                                        });
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2019:2080" />
                                </div>
                            </div>

                            <div style={{ minHeight: 400 }}>
                                <DataTable value={this.state.liste_ligne} footerColumnGroup={footerGroup}
                                    responsive={true} paginator={true} rows={15} alwaysShowPaginator={false} selectionMode="single" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                                    selection={this.state.selectedLigne} responsiveLayout='scroll'>
                                    <Column field="idPeval.idType.rangType" header="No" body={this.onIndexTemplate} style={{ width: '5%' }} />
                                    <Column field="idPeval.idType.libType" header="Indicateur" />
                                    <Column field="idPeval.puPeval" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000' }}>{this.FbrService.formaterValueSep(rowData.idPeval.puPeval)}</span>
                                        </div>
                                    }} header="Prix" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="valPrevBudget" header="CA" style={{ width: '8%' }} editor={(options) => this.quantEditor(options)} onCellEditComplete={this.onCellEditComplete} filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="valPrevBudget" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(rowData.valPrevBudget * rowData.idPeval.puPeval)}</span>
                                        </div>
                                    }} header="Montant" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                </DataTable>
                            </div>

                            <br />
                            <center style={{}}>
                                <div className="grid" style={{ width: 300 }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" className="p-button-secondary" onClick={() => this.annule_budget()} /></div>
                                    <div className="col"><Button label="Terminer" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_budget()} /></div>
                                </div>
                            </center>
                        </div>
                        :
                        <>
                            <Toolbar style={{ paddingBlock: 5 }} className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                            <div className="grid">
                                <div className="col-12">
                                    <div className="card-w-title">
                                        <i className="pi pi-search"></i> Option de recherche
                                        <hr style={{ marginTop: 0 }} />
                                        <div className="grid">
                                            <div className="col-12 md:col-3">
                                                <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                                    this.get_liste_data();
                                                })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="col-12 md:col-3">
                                                <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                                    this.get_liste_data();
                                                })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                            </div>
                                            <div className="col-12 md:col-3">
                                                <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label,typePrest' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                                    this.get_liste_data();
                                                })} panelStyle={{ width: '500px' }} appendTo={document.body} filterInputAutoFocus={false} />
                                            </div>

                                            <div className="col-12 md:col-2">
                                                <Button icon='pi pi-search' loading={this.state.showindicator} style={{ width: 150 }} label='Rechercher' onClick={() => this.get_liste_data()} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div style={{ minHeight: 590, padding: 1 }}>
                                <DataTable value={this.state.liste_budget} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    responsive={true} paginator={true} rows={12} alwaysShowPaginator={false} selectionMode="single"
                                    onRowClick={e => this.setState({ selectedData: e.data })}
                                    selection={this.state.selectedData} responsiveLayout='scroll'>
                                    <Column field="idBudget" header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} sortable />
                                    <Column field="idPeval.idType.rangType" header="No" sortable={true} style={{ width: '5%' }} />
                                    <Column field="dateBudget" body={(rowData, column) => {
                                        return <div>
                                            <span >{rowData.dateBudget.substring(3, 10)}</span>
                                        </div>
                                    }} header="Mois" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                    <Column field="idPeval.idType.libType" header="Indicateur" filter={true} filterMatchMode='contains' sortable={true} />
                                    <Column field="valPrevBudget" header="CA" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                    <Column field="valPrevBudget" body={(rowData, column) => {
                                        return <div>
                                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(rowData.valPrevBudget * rowData.idPeval.puPeval)}</span>
                                        </div>
                                    }} header="Montant" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                    <Column field="idPeval.idPrest.nomPrest" header="Acteur" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                    <Column field="idPeval.idPrest.typePrest.libCodif" header="Type" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                </DataTable>
                            </div>


                        </>
                }

            </div>
        );
    }
}
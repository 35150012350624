import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';

export class FormBanque extends Component {
    constructor() {
        super();
        this.state = {
            listecodif: [],
            selectedCodif: null,
            idCodif: 0,
            libCodif: '',
            typeCodif: 'BANQUE',
            descCodif: '',
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            visible_dlg_form: false,
        };
        this.edit_codif = this.edit_codif.bind(this);
        this.conf_delete_codif = this.conf_delete_codif.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);

        this.FbrService = new FbrService();

    }


    componentDidMount() {
        this.get_liste_codif();
    }

    get_liste_codif() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_codif(this.state.typeCodif).then(data => this.setState({ listecodif: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_codif();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_codif(data) {
        if (data != null && data != undefined) {
            this.setState({ idCodif: data.idCodif });
            this.setState({ libCodif: data.libCodif });
            this.setState({ descCodif: data.descCodif });
            this.setState({ modif: true });
            this.setState({ selectedCodif: data });
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_codif() {
        this.setState({ libCodif: '' });
        this.setState({ idCodif: 0 });
        this.setState({ descCodif: '' });
        this.setState({ modif: false, visible_dlg_form: false });
        this.forceUpdate();
    }

    enreg_codif() {
        if (this.state.libCodif == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let codification = {
                    idCodif: 0,
                    descCodif: this.state.descCodif,
                    libCodif: this.state.libCodif,
                    ciblePrest: -1,
                    typeCodif: this.state.typeCodif
                }
                this.FbrService.enreg_codif(codification)
                    .then(data => this.resultat(data.code, data.contenu));
            } else {
                let codification = this.state.selectedCodif;
                codification.libCodif = this.state.libCodif;
                codification.descCodif = this.state.descCodif;
                this.FbrService.enreg_codif(codification).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_codif(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_codif(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_codif() {
        if (this.state.selectedCodif == null || this.state.selectedCodif == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_codif(this.state.selectedCodif).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_codif(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedCodif: data });
            this.setState({ msg: data.libCodif });
            this.setState({ visible: true });
        }
    }

    toogleDlg() {
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" style={{ width: 120 }} onClick={this.toogleDlg} />
                <Button label="Supprimer" disabled={this.state.selectedCodif == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_codif(this.state.selectedCodif)} style={{ width: 115 }} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_codif();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid card" style={{ minHeight: 1000 }}>

                <Toolbar style={{ paddingBlock: 5 }} className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>



                <DataTable value={this.state.listecodif} paginatorPosition="top" selectionMode="single" paginator={true} rows={100} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedCodif: e.data })} onRowSelect={e => this.setState({ selectedCodif: e.data })}
                    responsive={true} selection={this.state.selectedCodif} >
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                    <Column field="libCodif" filter={true} filterMatchMode='contains' header="Nom banque" sortable={true} />
                    <Column field="descCodif" filter={true} filterMatchMode='contains' header="Adresse" sortable={true} />
                </DataTable>

                <Dialog visible={this.state.visible_dlg_form} style={{ width: 450 }} header={this.state.modif == true ? "Modification" : "Nouvelle banque"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={
                        <>
                            <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_codif()} />
                            <Button label="Terminer" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_codif()} style={{ width: 120 }} />
                        </>
                    }>
                    <div className="card">
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-3">
                                <label htmlFor="libCodif">Banque *</label>
                            </div>

                            <div className="field col-12 md:col-9">
                                <InputText value={this.state.libCodif} onChange={(e) => this.setState({ libCodif: e.target.value })} />
                            </div>

                            <div className="field col-12 md:col-3">
                                <label htmlFor="typeCodif">Adresse</label>
                            </div>
                            <div className="field col-12 md:col-9">
                                <InputText id="libCodif" value={this.state.descCodif} onChange={(e) => this.setState({ descCodif: e.target.value })} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </div>

        );
    }
}
import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

export class FormRestriction extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_bailleur: [],
            selectedData: null,
            idRestric: 0,
            libRestric: 'NA',
            actifRestric: 'Oui',
            debRestric: new Date(),
            finRestric: new Date(),
            tableRestric: 'Rapportage',
            profilRestric: null,
            idBailleur: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.FbrService = new FbrService();

    }


    componentDidMount() {
        this.get_liste_data();
        this.FbrService.get_liste_codif('BAILLEUR').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_bailleur: liste });
            }
        });
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_restriction().then(data => this.setState({ liste_data: data, showindicator: false }));
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_data();
            this.get_liste_data();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_data(data) {
        if (data != null && data != undefined) {
            this.setState({ idRestric: data.idRestric });
            this.setState({ libRestric: data.libRestric });
            this.setState({ profilRestric: data.profilRestric });
            this.setState({ tableRestric: data.tableRestric });
            this.setState({ idBailleur: data.idBailleur.idCodif });
            this.setState({ actifRestric: data.actifRestric });
            this.setState({ debRestric: new Date(moment(data.debRestric, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ finRestric: new Date(moment(data.finRestric, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ modif: true });
            this.setState({ selectedData: data });
        }
    }

    annule_data() {
        this.setState({ debRestric: '' });
        this.setState({ finRestric: '' });
        this.setState({ idRestric: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_data() {
        if (this.state.debRestric == "" || this.state.finRestric == "" || this.state.profilRestric == "" || this.state.tableRestric == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let data = {
                    idRestric: 0,
                    profilRestric: this.state.profilRestric,
                    libRestric: this.state.libRestric,
                    actifRestric: this.state.actifRestric,
                    debRestric: moment(this.state.debRestric, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    finRestric: moment(this.state.finRestric, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    tableRestric: this.state.tableRestric,
                    idBailleur: { idCodif: this.state.idBailleur },
                }
                this.FbrService.enreg_restriction(data)
                    .then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedData;
                data.libRestric = this.state.libRestric;
                data.actifRestric = this.state.actifRestric;
                data.profilRestric = this.state.profilRestric;
                data.debRestric = moment(this.state.debRestric, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.finRestric = moment(this.state.finRestric, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.idBailleur = { idCodif: this.state.idBailleur };
                this.FbrService.enreg_restriction(data).then(rep => this.resultat(rep.code, rep.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_data(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_restriction(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: data.profilRestric });
            this.setState({ visible: true });
        }
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">

                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <label htmlFor="libRestric">Profil *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown value={this.state.profilRestric} options={
                                        [
                                            { label: 'Administrateur', value: 'Administrateur' },
                                            { label: 'AGFA', value: 'AGFA' },
                                            { label: 'Coordinateur', value: 'Coordinateur' },
                                            { label: 'OBC', value: 'OBC' },
                                            { label: 'Prestataire', value: 'Prestataire' },
                                            { label: 'Payeur', value: 'Payeur' },
                                            { label: 'Agent de saisie', value: 'Agent de saisie' },
                                            { label: 'Consultation', value: 'Consultation' },
                                        ]
                                    } optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ profilRestric: e.target.value })} autoWidth={false} appendTo={document.body} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="libRestric">Référence *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown value={this.state.tableRestric} options={
                                        [
                                            { label: 'Rapportage', value: 'Rapportage' },
                                            { label: 'Payement', value: 'Payement' },
                                            { label: 'Enquete', value: 'Enquete' },
                                        ]
                                    } optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ tableRestric: e.target.value })} autoWidth={false} appendTo={document.body} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="tableRestric">Date début *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.debRestric} onChange={(e) => this.setState({ debRestric: e.target.value })} appendTo={document.body} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="tableRestric">Date fin *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.finRestric} onChange={(e) => this.setState({ finRestric: e.target.value })} appendTo={document.body} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="libRestric">Actif *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown value={this.state.actifRestric} options={
                                        [
                                            { label: 'Oui', value: 'Oui' },
                                            { label: 'Non', value: 'Non' },
                                        ]
                                    } optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ actifRestric: e.target.value })} autoWidth={false} appendTo={document.body} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="idPrest">Bailleur * </label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown placeholder="Sélectionner" autoWidth={false} value={this.state.idBailleur} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_bailleur} onChange={(e) => {
                                        this.setState({ idBailleur: e.value });
                                    }} appendTo={document.body} />
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 300 }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_data()} /></div>
                                    <div className="col"><Button label="Terminer" icon="pi pi-check" onClick={() => this.enreg_data()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_data} paginatorPosition="top" selectionMode="single" paginator={true} rows={100} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" 
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                                responsive={true} selection={this.state.selectedData} >
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="profilRestric" filter={true} filterMatchMode='contains' header="Profil" sortable={true} />
                                <Column field="debRestric" filter={true} filterMatchMode='contains' header="Date début" sortable={true} style={{ width: '15%' }} />
                                <Column field="finRestric" filter={true} filterMatchMode='contains' header="Date fin" sortable={true} style={{ width: '15%' }} />
                                <Column field="tableRestric" filter={true} filterMatchMode='contains' header="Référence" sortable={true} style={{ width: '17%' }} />
                                <Column field="actifRestric" filter={true} filterMatchMode='contains' header="Actif" sortable={true} style={{ width: '8%' }} />
                                <Column field="idBailleur.libCodif" filter={true} filterMatchMode='contains' header="Bailleur" sortable={true} style={{ width: '15%' }} />
                            </DataTable>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
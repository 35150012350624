import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';
import { SplitButton } from 'primereact/splitbutton';
import { InputNumber } from 'primereact/inputnumber';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';

export class FormDepense extends Component {
    constructor() {
        super();
        this.state = {
            liste_depense: [],
            liste_prest: [],
            liste_prest_rech: [],
            liste_type: [],
            selectedDep: null,
            montant_total: '0',
            iddep: '',
            motifDep: '',
            mtDep: 0,
            ficJoint: 'NA',
            typeDep: null,
            dateDep: new Date(),
            ridPrest: null,
            retatDep: null,
            file: null,
            msg: '',
            modif: false,
            visible: false,
            idPrest: null,
            pd1: new Date(),
            pd2: new Date(),
            rtypeDep: null,
            liste_etat: [
                { label: 'Etat', value: 'Tous' },
                { label: 'En attente', value: 'En attente' },
                { label: 'Validé', value: 'Validé' },
                { label: 'Annulé', value: 'Annulé' }
            ],
            items: [
                {
                    label: 'Par date validation',
                    icon: 'pi pi-search',
                    command: (e) => {
                        this.get_listeDepense('ETAT');
                    }
                },
                {
                    label: 'Exporter en CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            visible_dlg_form: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.edit_depense = this.edit_depense.bind(this);
        this.conf_delete_depense = this.conf_delete_depense.bind(this);
        this.enreg_dep = this.enreg_dep.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.FbrService = new FbrService();
    }



    componentDidMount() {
        this.get_liste_prestataire();
        this.get_listeDepense();
        this.FbrService.get_liste_codif('TYPE_DEPENSE').then(data => {
            this.setState({ liste_type: data });
        });
    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        let liste_gnle = [{ value: 'Tous', label: 'Acteur' }];
        this.FbrService.get_liste_prestataire().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                   
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif, nomBailleur: x.idBailleur.libCodif, idBailleur: x.idBailleur.idCodif, idTypePrest: x.typePrest.idCodif }
                    }).filter(function (el) {
                        return (el.typePrest !== 'PERSONNE');
                    });

                    this.setState({ liste_prest: liste });
                    liste_gnle = liste_gnle.concat(liste);
                    this.setState({ liste_prest_rech: liste_gnle });
                }
            }
        });
    }


    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    get_listeDepense() {
        let rtype = 'Tous';
        let rid_prest = 'Tous';
        this.setState({ showindicator: true });
        let spd1 = moment(this.state.pd1, 'DD/MM/YYYY').format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2, 'DD/MM/YYYY').format('DD/MM/YYYY');
        if (this.state.ridPrest != undefined && this.state.ridPrest != null) {
            rid_prest = this.state.ridPrest;
        }

        if (this.state.rtypeDep != undefined && this.state.rtypeDep != null && this.state.rtypeDep != '') {
            rtype = this.state.rtypeDep;
        }
        this.setState({ montant_total: '0' });
        this.FbrService.get_liste_depense(spd1, spd2, rtype, rid_prest).then(data => {
            this.setState({ liste_depense: data, showindicator: false }, () => {
                if (data != null && data != undefined) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mtDep
                    }, initialValue);
                    this.setState({ montant_total: this.formaterMontant(tt) });
                }
            })
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_dep();
            this.get_listeDepense();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_depense(data) {
        if (data != null && data != undefined) {
            this.setState({ iddep: data.iddep });
            this.setState({ motifDep: data.motifDep });
            this.setState({ mtDep: data.mtDep });
            this.setState({ typeDep: data.typeDep.idCodif });
            this.setState({ idPrest: data.idPrest.idPrest });
            this.setState({ etatDep: data.etatDep });
            this.setState({ selectedDep: data });
            this.setState({ modif: true });
            this.setState({ visible_dlg_form: true });
        }
    }

    annule_dep() {
        this.setState({ iddep: '0' });
        this.setState({ motifDep: '' });
        this.setState({ mtDep: 0 });
        this.setState({ dateDep: null });
        this.setState({ selectedDep: null });
        this.setState({ modif: false, visible_dlg_form: false });
        this.forceUpdate();
    }

    toogleDlg() {
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    enreg_dep() {
        if (this.state.motifDep === "" || this.state.mtDep === "" || this.state.typeDep === null || this.state.typeDep == undefined || this.state.idPrest === "" || this.state.idPrest === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {

            if (this.state.modif == false) {
                let data = {
                    iddep: '0',
                    motifDep: this.state.motifDep,
                    mtDep: this.state.mtDep,
                    idPrest: { idPrest: this.state.idPrest },
                    typeDep: this.state.typeDep,
                    etatDep: 'En attente',
                    iduDep: { idUser: this.state.userconnected.idUser },
                    idPrest: { idPrest: this.state.idPrest }
                }
                this.FbrService.enreg_depense(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {
                let data = this.state.selectedDep;
                data.motifDep = this.state.motifDep;
                data.mtDep = this.state.mtDep;
                data.typeDep = { idCodif: this.state.typeDep };
                data.idPrest = { idPrest: this.state.idPrest };
                this.FbrService.enreg_depense(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.enreg_dep();
        }
    }



    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_depense(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_depense(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_depense() {
        if (this.state.selectedDep == null || this.state.selectedDep == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.xulaservice.delete_depense(this.state.selectedDep).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_depense(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedDep: data });
            this.setState({ msg: ' la ligne sélectionnée ' });
            this.setState({ visible: true });
        }
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" style={{ width: 120 }} onClick={this.toogleDlg} />
                <Button label="Supprimer" disabled={this.state.selectedDep == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_depense(this.state.selectedDep)} style={{ width: 115 }} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    render() {

        let ltype_rech = [{ value: 'Tous', label: 'Type' }];
        let ltype = null;
        if (this.state.liste_type != null && this.state.liste_type != undefined) {
            ltype = this.state.liste_type.map(x => {
                return { value: x.idCodif, label: x.libCodif }
            });
            ltype_rech = ltype_rech.concat(ltype);
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_depense();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid card" style={{ minHeight: 1000, paddingBlock: 10 }}>
                <Toast ref={(el) => this.toast = el} />
                <Toolbar style={{ paddingBlock: 5 }} className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <div className="grid" style={{margin: 10}}>
                    <div className="col-12" style={{ padding: 0 }}>
                        <i className="pi pi-search"></i> Option de recherche
                        <hr style={{ marginTop: 0 }} />
                        <div className="grid" style={{ padding: 0 }}>
                            <div className="col-6 md:col-2">
                                <Calendar dateFormat="dd/mm/yy" placeholder='Date début' value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} yearNavigator monthNavigator yearRange='2019:2050'/>
                            </div>
                            <div className="col-6 md:col-2">
                                <Calendar dateFormat="dd/mm/yy" placeholder='Date fin' value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} yearNavigator monthNavigator yearRange='2019:2050'/>
                            </div>

                            <div className="col-6 md:col-2">
                                <Dropdown autoWidth={false} placeholder="Type" options={ltype_rech} value={this.state.rtypeDep} onChange={e => this.setState({ rtypeDep: e.value })} filter={true} filterBy='label' filterMatchMode='contains' style={{ width: '100%' }} />
                            </div>
                            <div className="col-6 md:col-3">
                                <Dropdown autoWidth={false} options={this.state.liste_prest_rech} placeholder="Acteur" value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value })} filter={true} filterBy='label' filterMatchMode='contains' style={{ width: '100%' }} />
                            </div>
                            <div className="col-12 md:col-2">
                                <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_listeDepense()} icon="pi pi-search" model={this.state.items}></SplitButton>
                            </div>
                            <div className="col-12 md:col-2">
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </div>
                        </div> 
                    </div>
                </div>

                <DataTable value={this.state.liste_depense} selectionMode="single" style={{marginTop: -30}}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedDep: e.data })} responsiveLayout='scroll'
                    responsive={true} selection={this.state.selectedDep} paginatorPosition="bottom" paginator={true} rows={10} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                    rowsPerPageOptions={[10, 20, 50, 100, 200]} csvSeparator=";" ref={(el) => { this.dt = el; }}>
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                    <Column field="dateDep" header="Date" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="motifDep" header="Motif dépense" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="mtDep" body={(rowData, column) => {
                        return <div>
                            <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.mtDep)}</span>
                        </div>
                    }} header="Montant" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="typeDep.libCodif" header="Type" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="idPrest.nomPrest" header="Acteur" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>

                <Dialog visible={this.state.visible_dlg_form} style={{ width: '70%' }} header={this.state.modif == true ? "Modification" : "Nouvelle dépense"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={
                        <>
                            <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annule_dep()} />
                            <Button label="Terminer" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_dep()} style={{ width: 120 }} />
                        </>
                    }>
                    <div className="card" style={{ paddingBlock: 0 }}>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-2">
                                <label htmlFor="motifDep">Motif dépense *</label>
                            </div>
                            <div className="field col-12 md:col-4">
                                <InputTextarea rows={2} id="motifDep" value={this.state.motifDep} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ motifDep: e.target.value })} />
                            </div>
                            <div className="field col-12 md:col-2">
                                <label htmlFor="mtDep">Montant *</label>
                            </div>
                            <div className="field col-12 md:col-4">
                                <InputNumber min={0} value={this.state.mtDep} maxFractionDigits={2} onChange={(e) => this.setState({ mtDep: e.value })} onKeyDown={this.handleKeyPress} />
                            </div>
                            <div className="field col-12 md:col-2">
                                <label htmlFor="typeDep">Type dépense *</label>
                            </div>
                            <div className="field col-12 md:col-4">
                                <Dropdown value={this.state.typeDep} options={ltype} filter={true} filterBy='label' onChange={(e) => this.setState({ typeDep: e.value })} autoWidth={false} />
                            </div>

                            <div className="field col-12 md:col-2">
                                <label htmlFor="idBoutiq">Prestataire *</label>
                            </div>
                            <div className="field col-12 md:col-4">
                                <Dropdown value={this.state.idPrest} options={this.state.liste_prest} filter={true} filterBy='label' onChange={(e) => this.setState({ idPrest: e.value }, () => {
                                })} autoWidth={false} />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Badge } from 'primereact/badge';

export class FormSite extends Component {
    constructor() {
        super();
        this.state = {
            listesite: [],
            selectedSite: null,
            selectedLigne: null,
            idSite: 0,
            nomSite: '',
            codeSite: '',
            crdSite: '',
            prefectureSite: '',
            regionSite: '',
            nomStructSite: '',
            codeStructSite: '',
            longitudeSite: '',
            latitudeSite: '',
            photoSite: 'photo.png',
            emailSite: '',
            adrSite: '',
            telSite: '',
            ouId: '',
            level: 5,
            typeSite: null,
            liste_type: [
                { label: 'TYPE 1', value: 'TYPE 1' },
                { label: 'TYPE 2', value: 'TYPE 2' },
                { label: 'TYPE 3', value: 'TYPE 3' }
            ],
            liste_region: [],
            liste_all_pref: [],
            liste_all_crd: [],
            liste_prefecture: [],
            liste_site_updated: [],
            liste_crd: [],
            region: { label: 'Boke', value: 'Boké' },
            pref: null,
            crd: null,
            file: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_site: false,
            visible_dlg_refresh: false,
            showindicator: false,
            liste_ou_fbr: [],
            liste_ou_global: [],
            liste_level_2_region: [], //IRS
            liste_level_3_pref: [], //DPS
            liste_level_4_crd: [], //Sous prefecture

        };
        this.edit_site = this.edit_site.bind(this);
        this.conf_delete_site = this.conf_delete_site.bind(this);
        this.conf_delete_new_ligne = this.conf_delete_new_ligne.bind(this);
        this.FbrService = new FbrService();
        this.toogleDlg = this.toogleDlg.bind(this);
        this.refresh_site = this.refresh_site.bind(this);
    }


    fileUpload(file, namefile) {
        this.FbrService.upload_photo_site(file, namefile, 'photos').then(data => {
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec chargement' });
            }
        });
    }

    componentDidMount() {
        this.get_liste_site();
        this.FbrService.get_liste_prefecture().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_pref: data });
            }
        });

        this.FbrService.get_liste_crd().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
            }
        });

        this.FbrService.get_liste_fosa().then(data => {
            this.setState({ showindicator: false });
            this.setState({ listeprest_updated: data });
        });

        this.setState({ liste_region: this.FbrService.get_liste_regions() })
    }

    get_liste_site() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_site().then(data => this.setState({ listesite: data, showindicator: false }));
    }

    select_pref(region, valPref) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        if (valPref !== undefined) {
            this.setState({ liste_prefecture: liste }, () => {
                this.setState({ prefectureSite: valPref });
            });
        } else {
            this.setState({ liste_prefecture: liste });
        }

    }

    select_crd(pref, valCrd) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        if (valCrd !== undefined) {
            this.setState({ liste_crd: liste }, () => {
                this.setState({ crdSite: valCrd });
            });
        } else {
            this.setState({ liste_crd: liste });
        }

    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_site();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_site(data) {
        if (data != null && data != undefined) {
            this.setState({ idSite: data.idSite });
            this.setState({ nomSite: data.nomSite });
            this.setState({ codeSite: data.codeSite });
            this.setState({ crdSite: data.crdSite });
            this.setState({ regionSite: data.regionSite });
            this.setState({ nomStructSite: data.nomStructSite });
            this.setState({ codeStructSite: data.codeStructSite });
            this.setState({ longitudeSite: data.longitudeSite });
            this.setState({ latitudeSite: data.latitudeSite });
            this.setState({ photoSite: data.photoSite });
            this.setState({ telSite: data.telSite });
            this.setState({ emailSite: data.emailSite });
            this.setState({ adrSite: data.adrSite });
            this.setState({ typeSite: { value: data.typeSite, label: data.typeSite } });

            this.select_crd(data.prefectureSite, data.crdSite);
            this.select_pref(data.regionSite, data.prefectureSite);
            this.select_crd(data.prefectureSite, data.crdSite);

            //this.select_crd(data.ouId, data.ouId);
            //this.select_crd(data.level, data.level);

            this.setState({ modif: true });
            this.setState({ selectedSite: data, ouId: data.ouId });
            this.setState({ visible_dlg_site: true });
        }
    }

    annule_site() {
        this.setState({ nomSite: '' });
        this.setState({ codeSite: '' });
        this.setState({ crdSite: '' });
        this.setState({ prefectureSite: '' });
        this.setState({ regionSite: '' });
        this.setState({ nomStructSite: '' });
        this.setState({ codeStructSite: '' });
        this.setState({ longitudeSite: '' });
        this.setState({ latitudeSite: '' });
        this.setState({ photoSite: '' });
        this.setState({ telSite: '' });
        this.setState({ emailSite: '' });
        this.setState({ adrSite: '' });
        this.setState({ typeSite: '' });
        this.setState({
            idSite: 0,
            ouId: '',
            level: 5,
        });
        this.setState({ modif: false });
        this.setState({ visible_dlg_site: false, visible_dlg_refresh: false });
        this.forceUpdate();
    }

    enreg_site() {
        if (this.state.nomSite == "" || this.state.crdSite == "" || this.state.regionSite == "" || this.state.prefectureSite == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoSite;
            if (this.state.file != null && this.state.file != undefined && this.state.file != '') {
                let name = this.state.file.name;
                upload = true;
                nomfic = this.state.nomSite + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let site = {
                    idSite: 0,
                    nomSite: this.state.nomSite,
                    codeSite: this.state.codeSite,
                    crdSite: this.state.crdSite,
                    prefectureSite: this.state.prefectureSite,
                    regionSite: this.state.regionSite,
                    nomStructSite: this.state.nomStructSite,
                    codeStructSite: this.state.codeStructSite,
                    longitudeSite: this.state.longitudeSite,
                    latitudeSite: this.state.latitudeSite,
                    photoSite: this.state.photoSite,
                    telSite: this.state.telSite,
                    emailSite: this.state.emailSite,
                    adrSite: this.state.adrSite,
                    ouId: this.state.ouId,
                    level: this.state.level
                }
                this.FbrService.enreg_site(site)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {
                let site = {
                    idSite: this.state.idSite,
                    nomSite: this.state.nomSite,
                    codeSite: this.state.codeSite,
                    crdSite: this.state.crdSite,
                    prefectureSite: this.state.prefectureSite,
                    regionSite: this.state.regionSite,
                    nomStructSite: this.state.nomStructSite,
                    codeStructSite: this.state.codeStructSite,
                    longitudeSite: this.state.longitudeSite,
                    latitudeSite: this.state.latitudeSite,
                    photoSite: this.state.photoSite,
                    telSite: this.state.telSite,
                    emailSite: this.state.emailSite,
                    adrSite: this.state.adrSite,
                    ouId: this.state.ouId,
                    level: this.state.level
                }
                this.FbrService.enreg_site(site)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_site(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_site(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_site() {
        if (this.state.selectedSite == null || this.state.selectedSite == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_site(this.state.selectedSite).then(data => this.resultat(data.code, data.contenu));
        }
    }


    refresh_site() {
        this.setState({ showindicator: true, visible_dlg_refresh: true, liste_site_updated: [] });
        this.FbrService.get_sync_site().then(data => this.setState({ liste_site_updated: data, showindicator: false }));

        /*
        this.FbrService.get_FBR_organisationUnit().then(data => {
            if (data !== undefined && data !== null && data.length>0) {
                this.setState({ liste_ou_fbr: data.organisationUnits }, () => {
                    let liste_site_updated = this.state.listesite;
                    this.FbrService.get_Global_organisationUnit().then(data2 => {
                        if (data2 !== undefined && data2 !== null && data2.length>0) {
                            this.setState({ liste_ou_global: data2.organisationUnits }, () => {
                                let lregion = this.state.liste_ou_global.filter(function (el2) {
                                    return el2.level == 2;
                                })
                                this.setState({ liste_level_2_region: lregion }, () => {
                                    let lpref = this.state.liste_ou_global.filter(function (el) {
                                        return el.level == 3;
                                    })
                                    this.setState({ liste_level_3_pref: lpref }, () => {
                                        let lcrd = this.state.liste_ou_global.filter(function (el) {
                                            return el.level == 4;
                                        })
                                        this.setState({ liste_level_4_crd: lcrd }, () => {
                                            for (var i = this.state.liste_ou_fbr.length; i--;) {
                                                this.state.liste_ou_fbr[i].idSite = 0;
                                                let id = this.state.liste_ou_fbr[i].id;
                                                let ou = this.state.liste_ou_global.filter(function (el3) {
                                                    return el3.id + '' === id;
                                                })[0];
                                                if (ou != undefined && ou != null) {
                                                    if (ou.level == 5) {

                                                        let ouId = ou.id;
                                                        let name = ou.name.toUpperCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                                        let index = liste_site_updated.findIndex(x => x.ouId === ouId || x.nomSite.toUpperCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '') === name);


                                                        let region = this.state.liste_level_2_region.filter(function (el) {
                                                            return el.id == ou.ancestors[1].id;
                                                        })[0];


                                                        let pref = this.state.liste_level_3_pref.filter(function (el) {
                                                            return el.id == ou.ancestors[2].id;
                                                        })[0];


                                                        let crd = this.state.liste_level_4_crd.filter(function (el) {
                                                            return el.id == ou.ancestors[3].id;
                                                        })[0];

                                                        let longitude = '';
                                                        let latitude = '';
                                                        if (ou.geometry !== null && ou.geometry !== undefined) {
                                                            let coordinates = ou.geometry.coordinates;
                                                            if (coordinates != null && coordinates != undefined) {
                                                                if (coordinates.length > 0) {
                                                                    longitude = coordinates[0] + '';
                                                                    latitude = coordinates[1] + '';
                                                                }
                                                            }
                                                        }

                                                        if (index < 0) {
                                                            liste_site_updated.push(
                                                                {
                                                                    idSite: '0',
                                                                    prefectureSite: pref.name,
                                                                    regionSite: region.name,
                                                                    crdSite: crd.name,
                                                                    nomSite: ou.name,
                                                                    ouId: ou.id,
                                                                    level: ou.level,
                                                                    code: ou.code,
                                                                    iduser: userconnected.idUser,
                                                                    longitudeSite: longitude,
                                                                    latitudeSite: latitude,
                                                                    statut: 'NEW',
                                                                }
                                                            )
                                                        } else {
                                                            liste_site_updated[index].prefectureSite = pref.name;
                                                            liste_site_updated[index].regionSite = region.name;
                                                            liste_site_updated[index].crdSite = crd.name;
                                                            liste_site_updated[index].nomSite = ou.name;
                                                            liste_site_updated[index].ouId = ou.id;
                                                            liste_site_updated[index].level = ou.level;
                                                            liste_site_updated[index].code = ou.code;
                                                            liste_site_updated[index].iduser = userconnected.idUser;
                                                            liste_site_updated[index].longitudeSite = longitude;
                                                            liste_site_updated[index].latitudeSite = latitude;
                                                            liste_site_updated[index].statut = 'OLD'
                                                        }

                                                        //console.log("Index of " + name + " : " + index);

                                                    } else {
                                                        this.state.liste_ou_fbr.splice(i, 1);
                                                    }
                                                }
                                            }
                                            for (var i = liste_site_updated.length; i--;) {
                                                liste_site_updated[i].ref = (i + 1);
                                            }
                                            this.setState({ liste_site_updated: liste_site_updated });
                                        });
                                    });
                                });

                                this.setState({ showindicator: false });
                            });
                        }

                    });
                })
            }
        });
        */
    }

    valider_refresh() {
        this.setState({ showindicator: true });
        this.FbrService.enreg_site_batch(this.state.liste_site_updated).then(data => {
            this.setState({ showindicator: false });
            if (data != null && data != undefined) {
                this.resultat(data.code, data.contenu);
            }
        });
    }

    conf_delete_site(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedSite: data });
            this.setState({ msg: data.nomSite });
            this.setState({ visible: true });
        }
    }



    exportCSV = () => {
        this.dt.exportCSV();
    }

    toogleDlg() {
        this.setState({ visible_dlg_site: !this.state.visible_dlg_site });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" style={{ width: 120 }} onClick={this.toogleDlg} />
                <Button label="Import DHIS2" icon="pi pi-refresh" style={{ width: 150 }} className="p-button-info" onClick={this.refresh_site} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Fonction de suppression du site sélectionné */
    conf_delete_new_ligne(data) {
        for (var i = this.state.liste_site_updated.length; i--;) {
            if (data.ref == this.state.liste_site_updated[i].ref) {
                this.state.liste_site_updated.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    actionTemplate_new_ligne(rowData, props) {
        return <Button onClick={() => this.conf_delete_new_ligne(rowData)} className="p-button-danger" style={{ width: 30, height: 30 }} icon="pi pi-times" />
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_site();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid card" style={{ minHeight: 1000 }}>
                <Toast ref={(el) => this.toast = el} />
                <Toolbar style={{ paddingBlock: 5 }} className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Synchronisation" visible={this.state.visible_dlg_refresh} style={{ width: '90%' }} modal={true} onHide={() => this.setState({ visible_dlg_refresh: false })}
                    footer={
                        <>
                            <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                this.setState({ visible_dlg_refresh: false });
                            }} />
                            <Button label="Terminer" icon="pi pi-check" className="p-button-info" onClick={() => {
                                this.valider_refresh();
                            }} />
                        </>
                    }>
                    <div className="card">
                        <center>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
                        <DataTable value={this.state.liste_site_updated} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={20}
                            alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })}
                            responsive={true} selection={this.state.selectedLigne} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            ref={(el) => { this.dtUpdated = el; }}
                            header={
                                <div className="grid" style={{ backgroundColor: '#D6EAF8', paddingBlock: 2 }}>
                                    <div className="col-12 md:col-2">
                                        <label htmlFor="idPrest">TOTAL</label>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <label style={{ fontWeight: 'bold', color: 'black' }}>{this.state.liste_site_updated.length}</label>
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <label htmlFor="idPrest">NOUVEAU</label>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <label style={{ fontWeight: 'bold', color: 'green' }}>{this.state.liste_site_updated.filter(function (el) {
                                            return el.statut == 'NEW';
                                        }).length}</label>
                                    </div>
                                </div>
                            }>
                            <Column header="#" body={this.actionTemplate_new_ligne.bind(this)} style={{ width: '5%' }} />
                            <Column field="ref" header="NO" style={{ width: '5%' }} />
                            <Column field="nomSite" filter={true} filterMatchMode='contains' header="NOM" sortable={true} />
                            <Column field="regionSite" filter={true} filterMatchMode='contains' header="DPS" sortable={true} />
                            <Column field="prefectureSite" filter={true} filterMatchMode='contains' header="ZONE" sortable={true} />
                            <Column field="crdSite" filter={true} filterMatchMode='contains' header="CR" sortable={true} />
                            <Column field="statut" body={(rowData, column) => {
                                let cl = 'badge-secondary';
                                if (rowData.statut == 'NEW') {
                                    cl = 'badge-success';
                                } else if (rowData.statut == undefined) {
                                    rowData.statut = 'OLD';
                                }
                                return <div>
                                    <Badge value={rowData.statut} className={cl} style={{ fontSize: 14 }} />
                                </div>
                            }} filter={true} filterMatchMode='contains' header="STATUT" sortable={true} style={{ width: '10%' }} />
                            <Column field="longitudeSite" body={(rowData, column) => {
                                return <div>
                                    <span style={{ color: '#0E6FDC' }} >{rowData.longitudeSite !== null ? <i className="pi pi-map-marker" style={{ fontSize: 22 }}></i> : null}</span>
                                </div>
                            }} header="LOC" sortable filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                        </DataTable>
                    </div>
                </Dialog>

                <Dialog visible={this.state.visible_dlg_site} style={{ width: '90%' }} header={this.state.modif == true ? "Modification" : "Nouveau site"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_site: false })}
                    footer={
                        <>
                            <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_site: false })} />
                            <Button label="Terminer" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_site()} style={{ width: 120 }} />
                        </>
                    }>
                    <div className="card">
                        <div className="p-fluid formgrid grid">
                            <div className="field col-3 md:col-2">
                                <label htmlFor="firstname2">Nom site *</label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <InputText id="nomSite" value={this.state.nomSite} onChange={(e) => this.setState({ nomSite: e.target.value })} />
                            </div>

                            <div className="field col-3 md:col-2">
                                <label htmlFor="lastname2">Code site</label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <InputText id="codeSite" value={this.state.codeSite} onChange={(e) => this.setState({ codeSite: e.target.value })} />
                            </div>

                            <div className="field col-3 md:col-2">
                                <label htmlFor="firstname2">Longitude</label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <InputText id="longitudeSite" value={this.state.longitudeSite} onChange={(e) => this.setState({ longitudeSite: e.target.value })} />
                            </div>

                            <div className="field col-3 md:col-2">
                                <label htmlFor="lastname2">latitude </label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <InputText id="codeSite" value={this.state.latitudeSite} onChange={(e) => this.setState({ latitudeSite: e.target.value })} />
                            </div>

                            <div className="field col-3 md:col-2">
                                <label htmlFor="firstname2">DPS *</label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <Dropdown placeholder="DPS" autoWidth={false} value={this.state.regionSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_region} onChange={(e) => {
                                    this.setState({ regionSite: e.value }, () => {
                                        this.select_pref(e.value);
                                        this.setState({ liste_crd: [] });
                                    });
                                }} appendTo={document.body} />
                            </div>

                            <div className="field col-3 md:col-2">
                                <label htmlFor="lastname2">ZONE *</label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <Dropdown placeholder="ZONE" editable={true} autoWidth={false} value={this.state.prefectureSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_prefecture} onChange={(e) => {
                                    this.setState({ prefectureSite: e.value }, () => {
                                        this.select_crd(e.value);
                                    });
                                }} appendTo={document.body} />
                            </div>

                            <div className="field col-3 md:col-2">
                                <label htmlFor="firstname2">CR *</label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <Dropdown placeholder="CR" editable={true} autoWidth={false} value={this.state.crdSite} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_crd} onChange={(e) => {
                                    this.setState({ crdSite: e.value });
                                }} appendTo={document.body} />
                            </div>


                            <div className="field col-3 md:col-2">
                                <label htmlFor="lastname2">Téléphone </label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <InputText id="codeSite" value={this.state.telSite} onChange={(e) => this.setState({ telSite: e.target.value })} />
                            </div>

                            <div className="field col-3 md:col-2">
                                <label htmlFor="firstname2">Email </label>

                            </div>
                            <div className="field col-9 md:col-4">
                                <InputText id="longitudeSite" value={this.state.emailSite} onChange={(e) => this.setState({ emailSite: e.target.value })} />
                            </div>


                            <div className="field col-3 md:col-2">
                                <label htmlFor="lastname2">ID DHIS2</label>
                            </div>
                            <div className="field col-9 md:col-4">
                                <InputText id="codeSite" value={this.state.ouId} onChange={(e) => this.setState({ ouId: e.target.value })} />
                            </div>

                        </div>
                    </div>

                </Dialog>




                <DataTable value={this.state.listesite} paginatorPosition="top" selectionMode="single" paginator={true} rows={20}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedSite: e.data })} onRowSelect={e => this.setState({ selectedSite: e.data })}
                    responsive={true} selection={this.state.selectedSite} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                    ref={(el) => { this.dt = el; }} resizableColumns="scroll" responsiveLayout='scroll'>
                    <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                    <Column field="nomSite" filter={true} filterMatchMode='contains' header="NOM" sortable={true} />
                    <Column field="regionSite" filter={true} filterMatchMode='contains' header="DPS" sortable={true} style={{ width: '12%' }} />
                    <Column field="prefectureSite" filter={true} filterMatchMode='contains' header="ZONE" sortable={true} style={{ width: '15%' }} />
                    <Column field="crdSite" filter={true} filterMatchMode='contains' header="CR" sortable={true} style={{ width: '20%' }} />
                    <Column field="longitudeSite" body={(rowData, column) => {
                        return <div>
                            <span style={{ color: '#0E6FDC' }} >{rowData.longitudeSite !== null ? <i className="pi pi-map-marker" style={{ fontSize: 22 }}></i> : null}</span>
                        </div>
                    }} header="LOC" sortable filter={true} filterMatchMode='contains' style={{ width: '9%' }} />
                    <Column field="ouId" filter={true} filterMatchMode='contains' header="ID DHIS2" sortable={true} style={{ width: '12%' }} />
                </DataTable>



            </div>
        );
    }
}
import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export class FormStatRapportageTrim extends Component {
    constructor() {
        super();
        this.state = {
            liste_annee: [{ label: parseInt(moment(new Date()).format('YYYY')), value: parseInt(moment(new Date()).format('YYYY')) }],
            rannee: parseInt(moment(new Date()).format('YYYY')),
            rtrim: '1',
            rRegion: null,
            rPref: null,
            rCrd: null,
            rtypePrest: null,
            ridPrest: null,
            liste_data: [],
            liste_type_prest: [],
            listeprest: [],
            listeprest_all: [],
            liste_region: [],
            liste_all_pref: [],
            liste_pref: [],
            liste_all_crd: [],
            liste_crd: [],
            liste_bailleur: [],
            ridBailleur: null,
            libM1: 'Jan',
            libM2: 'Fev',
            libM3: 'Mars',
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.FbrService = new FbrService();
    }


    componentDidMount() {
        let an = 2021;
        let liste_annee = [];
        while (an <= parseInt(moment(new Date()).format('YYYY'))) {
            liste_annee.push({ label: an, value: an });
            an++;
        }
        this.FbrService.get_liste_prefecture().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_pref: data });
            }
        });
        this.FbrService.get_liste_crd().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
            }
        });
        this.get_liste_codif();
        this.setState({ liste_region: this.FbrService.get_liste_regions(), liste_annee: liste_annee }, () => {
            //this.get_liste_data();
        });
    }


    get_liste_codif() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif, idTypePrest: x.typePrest.idCodif, regionSite: x.idSite.regionSite, prefectureSite: x.idSite.prefectureSite, crdSite: x.idSite.crdSite }
                    }).filter(function (el) {
                        return (el.typePrest == 'RECO-ASC' || el.typePrest == 'PS' || el.typePrest == 'Hopital' || el.typePrest == 'CS' || el.typePrest == 'DPS' || el.typePrest == 'DRS');
                    });
                    this.setState({ listeprest: liste, listeprest_all: liste });
                }
            }
        });
        this.FbrService.get_liste_codif('TYPE_PRESTATAIRE').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                }).filter(function (el) {
                    return (el.label == 'RECO-ASC' || el.label == 'PS' || el.label == 'Hopital' || el.label == 'CS' || el.label == 'DPS' || el.label == 'DRS');
                });
                this.setState({ liste_type_prest: liste }, () => {
                    this.setState({ rtypePrest: 325 });
                });
            }
        });

        this.setState({ showindicator: true });
        this.FbrService.get_liste_codif('BAILLEUR').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_bailleur: liste }, () => {
                    this.setState({ ridBailleur: liste[0].value });
                });
            }
        });
    }



    get_liste_data() {
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        let id_prest = 'Tous';
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }

        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            id_prest = this.state.ridPrest;
        }
        this.setState({ showindicator: true });
        this.FbrService.get_tt_rapportage_trim(this.state.rannee, this.state.rtrim, region, pref, crd, this.state.rtypePrest, id_prest, this.state.ridBailleur).then(data => this.setState({ liste_data: data, showindicator: false }));
    }



    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        this.setState({ liste_pref: liste });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        this.setState({ liste_crd: liste });
    }

    select_acteurs() {
        let liste = this.state.listeprest_all;
        if (this.state.listeprest_all != null && this.state.listeprest_all != undefined) {
            if (this.state.listeprest_all.length > 0) {
                let region = this.state.rRegion == null ? 'Tous' : this.state.rRegion;
                let pref = this.state.rPref == null ? 'Tous' : this.state.rPref;
                let crd = this.state.rCrd == null ? 'Tous' : this.state.rCrd;
                let type_prest = this.state.rtypePrest == null ? 'Tous' : this.state.rtypePrest;

                if (region !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.regionSite == region;
                    });
                }
                if (pref !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.prefectureSite == pref;
                    });
                }
                if (crd !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.crdSite == crd;
                    });
                }
                if (type_prest !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.idTypePrest == type_prest;
                    });
                }

            }
        }
        this.setState({ listeprest: liste });
    }


    render() {
        let headerGroup = <ColumnGroup>
            <Row>
                <Column header="N°" rowSpan={2} />
                <Column header="Indicateur" rowSpan={2} />
                <Column header="Quantités declarées" colSpan={4} />
                <Column header="Quantités validées" colSpan={4} />
                <Column header="Montant" rowSpan={2} />
            </Row>
            <Row>
                <Column header={this.state.libM1} field="valEvalLobj_1" />
                <Column header={this.state.libM2} field="valEvalLobj_2" />
                <Column header={this.state.libM3} field="valEvalLobj_3" />
                <Column header="TT" />

                <Column header={this.state.libM1} field="valConfLobj_1" />
                <Column header={this.state.libM2} field="valConfLobj_2" />
                <Column header={this.state.libM3} field="valConfLobj_3" />
                <Column header="TT" />

            </Row>
        </ColumnGroup>;

        return (
            <div className="card p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                            <hr style={{ marginTop: 0 }} />
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={this.state.liste_annee} optionLabel='label' placeholder='Année' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rannee} onChange={e => this.setState({ rannee: e.value }, () => {
                                        this.get_liste_data();
                                    })} style={{ width: '100%' }} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown id="idPrest" value={this.state.rtrim} options={[{ value: '1', label: 'T1' }, { value: '2', label: 'T2' }, { value: '3', label: 'T3' }, { value: '4', label: 'T4' }]} optionLabel='label' onChange={(e) => this.setState({ rtrim: e.target.value }, () => {
                                        let lm1 = '';
                                        let lm2 = '';
                                        let lm3 = '';

                                        if (this.state.rtrim == '1') {
                                            lm1 = 'Jan';
                                            lm2 = 'Fev';
                                            lm3 = 'Mars';
                                        } else if (this.state.rtrim == '2') {
                                            lm1 = 'Avr';
                                            lm2 = 'Mai';
                                            lm3 = 'Jun';
                                        } else if (this.state.rtrim == '3') {
                                            lm1 = 'Jul';
                                            lm2 = 'Aou';
                                            lm3 = 'Sep';
                                        } else {
                                            lm1 = 'Oct';
                                            lm2 = 'Nov';
                                            lm3 = 'Dec';
                                        }
                                        this.setState({
                                            libM1: lm1,
                                            libM2: lm2,
                                            libM3: lm3,
                                        }, () => {
                                            this.get_liste_data();
                                        })
                                    })} autoWidth={false} itemTemplate={this.itemTemplate} style={{ width: '100%' }} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'DPS', value: 'Tous' }].concat(this.state.liste_region)} optionLabel='label' placeholder='DPS' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rRegion} onChange={e => this.setState({ rRegion: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_pref(e.value);
                                        this.select_acteurs();
                                    })} style={{ width: '100%' }} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'ZONE', value: 'Tous' }].concat(this.state.liste_pref)} optionLabel='label' placeholder='ZONE' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rPref} onChange={e => this.setState({ rPref: e.value }, () => {
                                        this.get_liste_data();
                                        this.setState({ liste_crd: [] });
                                        this.select_crd(e.value);
                                        this.select_acteurs();
                                    })} style={{ width: '100%' }} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'CR', value: 'Tous' }].concat(this.state.liste_crd)} optionLabel='label' placeholder='CR' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rCrd} onChange={e => this.setState({ rCrd: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_acteurs();
                                    })} style={{ width: '100%' }} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={this.state.liste_type_prest} optionLabel='label' placeholder='Type acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rtypePrest} onChange={e => this.setState({ rtypePrest: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_acteurs();
                                    })} style={{ width: '100%' }} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ value: 'Tous', label: 'Acteur' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                        this.get_liste_data();
                                    })} panelStyle={{ width: '300px' }} style={{ width: '100%' }} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_bailleur} optionLabel='label' placeholder='Bailleur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridBailleur} onChange={e => this.setState({ ridBailleur: e.value }, () => {
                                        this.get_liste_data();
                                    })} style={{ width: '100%' }} />
                                </div>
                            </div>

                            <center>
                                <SplitButton label="Rechercher" style={{ width: '180px', }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                    </div>
                </div>



                <div className="grid">
                    <div className="col-12 lg-12">
                        <div className="grid">
                            <div className="col-12">
                                <DataTable value={this.state.liste_data} responsive={true} paginator={true} rows={40} alwaysShowPaginator={false} responsiveLayout='scroll'csvSeparator=";"
                                    ref={(el) => { this.dt = el; }} headerColumnGroup={headerGroup}
                                    className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                    <Column field="rangType" header="#" style={{ width: '5%' }} />
                                    <Column field="indicateur" header="Indicateur" />
                                    <Column field="valEvalLobj_1" header="QD_M1" style={{ width: '5%' }} />
                                    <Column field="valEvalLobj_2" header="QD_M2" style={{ width: '5%' }} />
                                    <Column field="valEvalLobj_3" header="QD_M3" style={{ width: '5%' }} />
                                    <Column field="valEvalLobj_tt" header="QD_TT" style={{ width: '5%' }} />

                                    <Column field="valConfLobj_1" header="QV_M1" style={{ width: '5%' }} />
                                    <Column field="valConfLobj_2" header="QV_M2" style={{ width: '5%' }} />
                                    <Column field="valConfLobj_3" header="QV_M3" style={{ width: '5%' }} />
                                    <Column field="valConfLobj_tt" header="QV_TT" style={{ width: '5%' }} />

                                    <Column field="mtQuant" body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.mtQuant))}</span>
                                        </div>
                                    }} style={{ width: '15%' }} header="MONTANT" />

                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}